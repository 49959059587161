import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { BlogPostData } from '../../types';

import BlogTile from '../blog-tile';
import './more-blog-posts.css';

type MoreBlogPostProps = {
    node: BlogPostData;
};

const MoreBlogPosts: React.FC<{ posts: MoreBlogPostProps[] }> = ({ posts }) => {
    const isMobile = useMediaQuery('(max-width: 745px)');

    return !posts.length ? (
        <></>
    ) : (
        <section className="more-posts-section">
            <div className="container">
                <p className="text-2xl bold teal">More Insights</p>
                <div className="more-posts-wrapper" style={{ marginInline: "auto", width: (isMobile || posts.length === 3) ? "100%" : "80%" }}>
                    {posts.map((post: MoreBlogPostProps, i: number) => (
                        <BlogTile
                            key={`more-posts-${i}`}
                            to={post.node.slug}
                            title={post.node.title}
                            image={post.node.image.fluid}
                            author={post.node.author.name}
                            date={post.node.createdAt}
                        />
                    ))}
                </div>
            </div>
        </section >
    );
};

export default MoreBlogPosts;
