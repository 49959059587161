import React from 'react';
import Img from 'gatsby-image';
import { Document, BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents, Options, CommonNode } from '@contentful/rich-text-react-renderer';

import { ContentfulAsset } from '../../types';
import './blog-body.css';
import BlogSubscribe from '../blog-subscribe';
import useMediaQuery from '../../hooks/useMediaQuery';

type BlogBodyProps = {
    raw: string;
    references: ContentfulAsset[];
};

type RichTextAssetNode = {
    content: any[];
    data: {
        target: {
            sys: {
                id: string;
                type: string;
                linkType: string;
            };
        };
    };
    nodeType: string;
};

const BlogBody: React.FC<BlogBodyProps> = ({ raw, references }) => {
    const isMobile = useMediaQuery("(max-width:745px)");

    const richTextContent: Document = JSON.parse(raw);
    const options: Options = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node: CommonNode, children: any) => (
                <h2 className="text-2xl text-black-2 semi-bold" style={{ textTransform: "capitalize", marginBottom: '25px', marginTop: '42px', lineHeight: "132%" }}>
                    {children}
                </h2>
            ),
            [BLOCKS.HEADING_3]: (node: CommonNode, children: any) => (
                <h3 className="text-xl text-black-2 semi-bold" style={{ marginBottom: '25px', marginTop: '42px', fontSize: isMobile ? "2em" : "2.2em" }}>
                    {children}
                </h3>
            ),
            [BLOCKS.PARAGRAPH]: (node: CommonNode, children: any) => (
                <p className="text-md font-normal text-black" style={{ marginBottom: '25px', marginTop: '13px', fontSize: "1.8em" }}>
                    {children}
                </p>
            ),
            // @ts-ignore: There isn't much TS support for this library
            [BLOCKS.TABLE]: (node, children) => (
                <table>
                    <tbody>{children}</tbody>
                </table>
            ),
            // @ts-ignore
            [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
            // @ts-ignore
            [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
            // @ts-ignore
            [BLOCKS.EMBEDDED_ASSET]: (node: RichTextAssetNode) => {
                const nodeId = node.data.target.sys.id;
                const asset = references.find((asset: ContentfulAsset) => asset.contentful_id === nodeId);
                return asset ? (
                    <figure>
                        <Img fluid={asset.fluid} className="blog-post-body-img" alt="blog post body image" />
                        {asset.description.length && <figcaption>{asset.description}</figcaption>}
                    </figure>
                ) : (
                    <></>
                );
            }
        }
    };

    const richText = documentToReactComponents(richTextContent, options);

    let asideData;

    asideData = richTextContent.content.filter(each => each.nodeType === 'heading-2');

    if (asideData.length === 0) {
        asideData = richTextContent.content.filter(each => each.nodeType === 'heading-3');
    }

    React.useEffect(() => {
        // set an attribute of id in h2 or h3 tags in blog body cotent to have them as internal link
        let headTag;
        const parentContainer = document.querySelector(".rendered-content-section");
        headTag = parentContainer?.getElementsByTagName('h2') || [];

        if (headTag.length === 0) {
            headTag = parentContainer?.getElementsByTagName('h3') || [];
        }

        for (let i = 0; i < headTag?.length; i++) {
            headTag[i].setAttribute("id", makeInternalLink(headTag[i]?.textContent))
        }
    }, [])

    const makeInternalLink = (link: string) => {
        return link.toLowerCase().replace(/\s/g, "-")
    }
    return (
        <section className="blog-body-section">
            <div className="container body-wrapper">

                <aside>
                    <div className='toc-blog-wrapper'>
                        <h2 className='bold' style={{ fontSize: "1.8em", marginTop: "2em", marginBottom: "1em" }}>Table of contents</h2>
                        <div className='aside-list-wrapper'>
                            {asideData?.map(each => {
                                let value = each?.content[0]?.value;
                                return <a href={`#${makeInternalLink(value)}`} style={{ textDecoration: "none" }}> <p className='aside-list'>{value}</p></a>
                            }
                            )}
                        </div>
                    </div>
                    <h2 className='bold' style={{ fontSize: "1.8em", marginTop: "3em" }}>Stay updated with insights</h2>
                    <div style={{ marginTop: "5.5em" }}>
                        <BlogSubscribe flexCol />
                    </div>
                </aside>
                <div className='rendered-content-section'>
                    {richText}
                </div>
            </div>
        </section>
    );
};

export default BlogBody;
