import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { FluidImageProps } from '../../types';
import './blog-tile.css';

type BlogTileProps = {
    to: string;
    title: string;
    date: string;
    author: string;
    image: FluidImageProps;
    width?: string;
    highlight?: boolean;
};

const BlogTile: React.FC<BlogTileProps> = ({ to, title, date, author, image, width, highlight }) => {
    const tileClass = highlight ? 'blog-tile highlight' : 'blog-tile';

    console.log(highlight)
    return (
        <Link to={`/blog/${to}`} className={tileClass} style={{ width: width }}>
            <div className="image-wrapper" style={{ height: "100%", minWidth: "300px" }}>
                <Img fluid={image} alt={`AptEdge | ${title}`} />
            </div>

            <div className="blog-tile-text-wrapper">
                <h3 className={highlight ? 'text-2xl bold teal' : 'text-md bold teal'}>{title}</h3>
                <div className="blog-tile-details-wrapper flex text-black" style={{ gap: "1em", opacity: "0.45" }}>
                    <p className="text-sm  blog-tile-author medium">
                        {author}
                    </p>
                    <p className="text-sm medium">{date}</p>
                </div>
                <p className={highlight ? 'text-md font-normal text-black' : "text-black"}>{title}</p>
            </div>
        </Link>
    );
};

export default BlogTile;
