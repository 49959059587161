import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import './blog-post-hero.css';

type BlogPostHeroProps = {
    image: FluidObject;
    alt: string;
    title: string;
    date: string;
    author: string;
};

const BlogPostHero: React.FC<BlogPostHeroProps> = ({ image, alt, title, date, author }) => {
    return (
        <div className="blog-post-hero-wrapper">
            {/* <div className="blog-post-hero-text-wrapper">
                <h1 className="text-3xl bold white">{title}</h1>
                <div className="blog-post-hero-details-wrapper">
                    <p className="text-md white">{date}</p>
                    <p className="text-md white blog-post-hero-author">
                        <span style={{ opacity: '60%' }}>Posted by</span> {author}
                    </p>
                </div>
            </div> */}
            <Img fluid={image} alt={alt} />
            {/* <div className="blog-post-hero-overlay" /> */}
        </div>
    );
};

export default BlogPostHero;
