import React from 'react';

import HtmlHead from '../../components/html-head';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import PathNavigation from '../../components/path-navigation';
import BlogSubscribe from '../../components/blog-subscribe';
import BlogHero from '../../components/blog-post-hero';
import BlogBody from '../../components/blog-body';
import MoreBlogPosts from '../../components/more-blog-posts';
import { BlogPostData } from '../../types';

import './blog-post.css';
import CtaBlock from '../../components/components-group-one/CtaBlock';

type BlogPostProps = {
    pageContext: {
        currentPost: BlogPostData;
        otherPosts: {
            node: BlogPostData;
        }[];
    };
};

const BlogPost: React.FC<BlogPostProps> = ({ pageContext }) => {
    console.log(pageContext.otherPosts)
    return (
        <>
            <HtmlHead
                title={pageContext.currentPost.title}
                metaDescription={pageContext.currentPost.metaDescription.metaDescription}
                openGraphImage={pageContext.currentPost.image.file.url}
                canonicalLink={pageContext.currentPost.canonicalLink}

            />
            <Navbar />
            <div className="blog-post-bg">
                <div className='hero-wrapper'>
                    <section className='blog-post-hero'>
                        <header>
                            <h1 className='teal text-3xl bold'>{pageContext.currentPost.title}</h1>
                            <div className='flex teal' style={{ gap: "1em" }}>
                                <p className='meta-data'>{pageContext.currentPost.author.name}</p>
                                <p className='meta-data'>{pageContext.currentPost.createdAt}</p>
                            </div>
                        </header>
                        <div className="container large" style={{ paddingTop: "3em" }}>
                            <BlogHero
                                image={pageContext.currentPost.image.fluid}
                                alt={pageContext.currentPost.title}
                                title={pageContext.currentPost.title}
                                date={pageContext.currentPost.createdAt}
                                author={pageContext.currentPost.author.name}
                            />
                        </div>
                    </section>
                </div>
                <BlogBody raw={pageContext.currentPost.body.raw} references={pageContext.currentPost.body.references} />
                <div style={{ marginBottom: "11em" }}>
                    <CtaBlock />
                </div>
                <MoreBlogPosts posts={pageContext.otherPosts} />
            </div>
            <Footer />
        </>
    );
};

export default BlogPost;
